<template>
  <div>
    <div class="header_message">
      <span>关键词：</span>
      <a-radio-group
        v-model="tabPosition"
        style="margin: 8px"
        button-style="solid"
        @change="onChangeKey"
      >
        <a-radio-button
          v-for="(item, index) in keyWordList"
          :key="index"
          :value="item"
        >
          {{ item }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <a-table
      :columns="columns"
      :data-source="listData"
      :pagination="false"
      :rowKey="(record, index) => index"
      :loading="tableLoading"
    >
      <div slot="awemeId" slot-scope="text, record" class="video_title">
        <div class="cover_box">
          <img :src="record.aweme_cover" alt="" />
        </div>
        <div class="title">
          <div class="aweme-title">{{ record.aweme_title || "-" }}</div>
          <div class="aweme-dealer">{{ record.nickname || "-" }}</div>
        </div>
      </div>
      <span slot="digg_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="comment_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="share_count" slot-scope="text">
        {{ text | changeNum }}
      </span>
      <span slot="operation" slot-scope="text, record" class="operation_box">
        <a @click="() => openVideo(record.share_url)" style="display: block">查看</a>
        <a @click="() => openDetail(record.aweme_id)" style="display: block">详情</a>
      </span>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </div>
</template>

<script>
const columns = [
  {
    title: "视频信息",
    dataIndex: "aweme_id",
    scopedSlots: { customRender: "awemeId" },
    ellipsis: true,
    width: 300,
  },
  {
    title: "播放量",
    dataIndex: "play_count",
    align: "center",
    width: 100,
  },
  {
    title: "点赞量",
    dataIndex: "digg_count",
    scopedSlots: { customRender: "digg_count" },
    align: "center",
    width: 100,
  },
  {
    title: "评论量",
    dataIndex: "comment_count",
    scopedSlots: { customRender: "comment_count" },
    align: "center",
    width: 100,
  },
  { title: "发布时间", dataIndex: "publish_time", align: "center", width: 120 },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
    width: 90,
  },
];
export default {
  data() {
    return {
      columns,
      listData: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 17 },
      },
      brandSelectList: [],
      tableLoading: false,
      tabPosition: "",
      keyWordList: [],
    };
  },
  mounted() {
    // this.getDataList(1, 10);
    this.getInitData();
  },
  methods: {
    // 初始请求
    async getInitData() {
      await this.getKeyWords();
      this.getDataList(1, 10);
    },
    // 获取关键词列表
    async getKeyWords() {
      await this.$api.core.Polymers.getKeyWords().then((res) => {
        if (res.code == 0) {
          this.keyWordList = res.data;
          this.tabPosition = res.data[0];
        }
      });
    },

    // 获取列表数据
    getDataList(current, size) {
      let paylod = {
        size,
        current,
        keyword: this.tabPosition,
      };
      this.tableLoading = true;

      this.$api.core.Polymers.getKeyWordVideo(paylod)
        .then((res) => {
          console.log("返回的res", res);
          if (res.data) {
            this.listData = res.data.data;
            this.pagination = res.data.pagination;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 查看详情
    openDetail(id) {
      this.$router.push({
        name: 'polymers:keywords_monitoring_detail',
        query: {
          key: this.tabPosition,
          id
        }
      });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getDataList(current, pageSize);
    },
    onChangeKey() {
      this.getDataList(1, 10);
    },
    openVideo(url) {
      if (url) {
        window.open(url);
      } else {
        this.$message.warning("链接不存在");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.flex-wrap {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.ml-10p {
  margin-left: 10px;
}

.header_message {
  font-size: 16px;
  font-weight: 500;
  margin: 8px 0;
  text-align: left;
  display: flex;
  align-items: center;
}

.header_message /deep/ .ant-radio-button-wrapper {
  padding: 0 24px !important;
}

.updata_times {
  display: inline-block;
  margin: 0 26px;
}

.video_title {
  display: flex;
  //   align-items: center;
  .cover_box {
    display: block;
    height: 118px;
    width: 89px;
    margin-right: 10px;
    img {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
  }
  .title {
    flex: 1;
  }

  .aweme-title {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;
    font-weight: 500;
  }

  .aweme-dealer {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
}

.operation_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
